import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ZoomDipendenzeTemplate from '../components/template-uno-zoom';

const ZoomDipendenzePage = ({ data }) => {
  const {
    hero,
    page: { dipendenze, tagTitle, metaDescription, title, text }
  } = data;
  return (
    <Layout data={data}>
      <Seo
        title={tagTitle || title}
        ogImage={hero.childImageSharp.resize}
        description={metaDescription}
        data={data}
      />
      <ZoomDipendenzeTemplate title={title} text={text} dipendenze={dipendenze} hero={hero} />
    </Layout>
  );
};

export default ZoomDipendenzePage;
export const query = graphql`
  query DipendenzeQuery {
    ...HeaderFragment
    ...SeoFragment
    hero: file(relativePath: { eq: "tech-addiction.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, layout: CONSTRAINED, placeholder: TRACED_SVG)
        resize(width: 1200) {
          src
          width
          height
        }
      }
    }
    page: dipendenzePageJson {
      title
      tagTitle
      metaDescription
      text
      dipendenze {
        name
        image {
          publicURL
        }
        description
      }
    }
  }
`;
