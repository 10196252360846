import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import TemplateDipendenze from './template-dipendenze';
import Breadcrumb from './breadcrumb';

const ZoomDipendenzeTemplate = ({ hero, dipendenze, title, text }) => (
  <>
    {hero && (
      <div className="section mt-0 p-0">
        <div className="hero position-relative">
          <GatsbyImage className="hero__img" image={getImage(hero)} alt="" />
          <div className="breadcrumb__wrapper">
            <div className="container-xl">
              <div className="row">
                <div className="col-12">
                  <Breadcrumb className="breadcrumb--herox" breadcrumb={[{ name: title }]} />
                </div>
              </div>
            </div>
          </div>
          <div className="hero__content pb-3">
            <div className="container-xl text-center pb-5">
              <div className="row">
                <div className="col-12 col-md-10 offset-md-1 hero__citation">
                  "Non si diventa dipendenti da una macchina, ma dalle sensazioni ed esperienze che
                  si provano durante l'uso di quella macchina..."
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-md-10 offset-md-1 hero__author text-right">
                  Kimberly Young
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="section">
      <div className="container-xl">
        <div className="row">
          <div className="col">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </div>
    </div>
    <TemplateDipendenze dipendenze={dipendenze} />
  </>
);

export default ZoomDipendenzeTemplate;
